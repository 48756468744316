import React from 'react';
import ReactDOM from 'react-dom';
import { SWRConfig } from 'swr';
import { Switch, Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import '@wave/common/lib/i18n';

import { WaveThemeProvider } from '@wave/components';
import {
  SWRconfig as value,
  AuthUserProvider,
  Router,
  AuthorizedRoute,
} from '@wave/common';

import '@wave/common/dist/index.css';
import '@wave/components/dist/index.css';

import locations from 'data/locations/config';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
  environment: process.env.REACT_APP_ENV || process.env.NODE_ENV,
});

ReactDOM.render(
  <React.StrictMode>
    <WaveThemeProvider>
      <SWRConfig value={value}>
        <Router>
          <AuthUserProvider>
            <Switch>
              <AuthorizedRoute config={locations} />
              <Redirect to="/" />
            </Switch>
          </AuthUserProvider>
        </Router>
      </SWRConfig>
    </WaveThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
