import CheckCoupon from '../../pages/CheckCoupon';

import { Profile, NavigationProfile } from '@wave/common';
import { CouponIcon } from '@wave/components';

const checkCoupon = {
  name: 'checkCoupon',
  page: '/',
  exact: true,
  component: CheckCoupon,
  text: 'navigation.check',
  icon: CouponIcon,
};

const profile = {
  name: 'profile',
  page: '/profile',
  component: Profile,
  navComponent: NavigationProfile,
};

const locations = {
  scope: 'merchant',
  defaultUrl: '/',
  routes: [checkCoupon, profile],
  navigation: [checkCoupon, profile],
};

export default locations;
