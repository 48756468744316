import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';

import enProfile from './locales/en/profile.json';
import elProfile from './locales/el/profile.json';
import enTranslation from './locales/en/translation.json';
import elTranslation from './locales/el/translation.json';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: false,
    },
    debug: false,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      requestOptions: {
        cache: 'no-store',
      },
    },
  });

i18n.addResourceBundle('en', 'profile', enProfile);
i18n.addResourceBundle('el', 'profile', elProfile);
i18n.addResourceBundle('en', 'translation', enTranslation);
i18n.addResourceBundle('el', 'translation', elTranslation);

export default i18n;
