import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { PrimaryButton, ErrorIcon } from '@wave/components';
import { GeneralMessageDialog, ResponseMessageDialog } from '@wave/common';

import styles from './CheckCouponDialog.module.scss';

const CheckCouponDialog = ({
  data,
  isOpen,
  closeDialog,
  errorMessage,
  error,
  loading,
  redeemRequest,
  redeemDialog,
}) => {
  const { t, ready } = useTranslation(['merchant']);
  const validHeading = ready ? t(`merchant:modal:headings:valid`) : '';
  const bodies = ready
    ? t(`merchant:modal:bodies`, { returnObjects: true })
    : '';
  const buttons = ready
    ? t(`merchant:modal:buttons`, { returnObjects: true })
    : '';
  const infoTypes = ready
    ? t(`merchant:modal:info`, { returnObjects: true })
    : '';

  const info =
    data !== null
      ? Object.keys(infoTypes).map((infoType, index) => {
          if (data[infoType]) {
            if (typeof data[infoType] === 'number') {
              data[infoType] = dayjs(data[infoType]).format(
                'DD/MM/YYYY - HH:mm'
              );
            } //dates process
            return (
              <p key={index}>
                {infoTypes[infoType]} <strong>{data[infoType]}</strong>
              </p>
            );
          }
          return '';
        })
      : '';

  return (
    <>
      {redeemDialog === 'close' ? (
        <GeneralMessageDialog
          open={isOpen}
          closeDialog={closeDialog}
          dialogActions={
            error ? (
              <PrimaryButton onClick={closeDialog}>
                {buttons.back}
              </PrimaryButton>
            ) : (
              <>
                <PrimaryButton onClick={closeDialog}>
                  {buttons.cancel}
                </PrimaryButton>
                <PrimaryButton onClick={redeemRequest}>
                  {buttons.redeem}
                </PrimaryButton>
              </>
            )
          }
          dialogHeader={error ? errorMessage : validHeading}
          isLoading={loading}
        >
          {error && data === null ? <ErrorIcon className={styles.icon} /> : ''}
          {info}
          <div className={styles.helper}>
            <p>{error ? bodies.merchantError : ''}</p>
            {data !== null ? (
              <p>
                <small>{bodies.dateDisclaimer}</small>
              </p>
            ) : (
              ''
            )}
          </div>
        </GeneralMessageDialog>
      ) : (
        <ResponseMessageDialog
          open={isOpen}
          closeDialog={closeDialog}
          success={redeemDialog === 'success' ? true : false}
          dialogHeader={`${infoTypes.name} ${data.name}`}
          dialogActions={
            redeemDialog === 'success' ? (
              <PrimaryButton onClick={closeDialog}>{buttons.ok}</PrimaryButton>
            ) : (
              <PrimaryButton onClick={redeemRequest}>
                {buttons.retry}
              </PrimaryButton>
            )
          }
          isLoading={loading}
        >
          {bodies.redeeming} <strong>{data.code}</strong>
          {redeemDialog === 'success'
            ? bodies.redeemingSuccess
            : bodies.redeemingError}
        </ResponseMessageDialog>
      )}
    </>
  );
};

export default CheckCouponDialog;
