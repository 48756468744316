import React, { useState } from 'react';

import { Form, FormSubmitButton, TextInput } from '@wave/components';
import { useDialog } from '@wave/hooks';

import { checkCouponCode, redeemCoupon } from '@wave/common';

import styles from './CheckCouponForm.module.scss';
import CheckCouponDialog from 'components/CheckCouponDialog';

const CheckCouponForm = ({ translation, lang }) => {
  const [data, setData] = useState({});
  const [redeemDialog, setRedeemDialog] = useState('close');

  const {
    isOpen,
    isLoading,
    hasError,
    openDialog,
    closeDialog,
    startLoading,
    stopLoading,
    handleError,
    dialogErrorMessage,
  } = useDialog();

  const onSubmit = async (data) => {
    startLoading();
    try {
      const response = await checkCouponCode(data?.checkCoupon);
      setData(response);
      openDialog();
      stopLoading();
    } catch (error) {
      openDialog();
      setData(error.response.data.error.params);
      handleError(error?.userMessage || '');
    }
  };

  const onClose = () => {
    setData({});
    setRedeemDialog('close');
    closeDialog();
  };

  const onRedeem = async () => {
    startLoading();
    try {
      await redeemCoupon({ code: data.code });
      setRedeemDialog('success');
      stopLoading();
    } catch (error) {
      handleError(error?.userMessage || '');
      setRedeemDialog('failure');
    }
  };

  return (
    <>
      <Form onSubmit={onSubmit}>
        <TextInput
          name="checkCoupon"
          label={translation.inputLabel}
          lang={lang}
        />
        <FormSubmitButton
          className={styles.button}
          label={translation.buttonLabel}
        />
      </Form>
      <CheckCouponDialog
        data={data}
        isOpen={isOpen}
        errorMessage={dialogErrorMessage}
        success={dialogErrorMessage ? false : true}
        showCloseButton={false}
        error={hasError}
        loading={isLoading}
        closeDialog={onClose}
        redeemRequest={onRedeem}
        redeemDialog={redeemDialog}
      />
    </>
  );
};

export default CheckCouponForm;
