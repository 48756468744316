import { useState, useCallback } from 'react';

const states = {
  open: 'open',
  error: 'error',
  close: 'close'
};
const {
  open,
  error,
  close
} = states;

const useDialog = (initialValue = 'close') => {
  const [state, setState] = useState(initialValue);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = useState('');

  const openDialog = () => {
    setState(open);
  };

  const closeDialog = () => {
    setState(close);
  };

  const startLoading = () => {
    setIsLoading(true);
  };

  const stopLoading = () => {
    setIsLoading(false);
  };

  const handleError = dialogErrorMessage => {
    setDialogErrorMessage(dialogErrorMessage);
    setIsLoading(false);
    setState(error);
  };

  return {
    state,
    isOpen: state === open || state === error,
    isLoading,
    hasError: state === error,
    openDialog,
    closeDialog,
    startLoading,
    stopLoading,
    handleError,
    dialogErrorMessage
  };
};

function useToggle(initialValue = false) {
  const [value, setValue] = useState(initialValue);
  const toggle = useCallback(() => {
    setValue(current => !current);
  }, []);
  const toggleExactValue = useCallback(value => {
    setValue(value);
  }, []);
  return {
    value,
    toggle,
    toggleExactValue
  };
}

export { useDialog, useToggle };
