import Cookies from 'js-cookie';

/**
 * Validates a value with the given validate function. It return true if it passes or the passed error message if it fails
 * @param {*} value - The value that needs validation
 * @param {function} validateFunction - The function that validates the value
 * @param {string} errorMessage - The error message that is returned if validation fails
 * @returns {(boolean|string)} true if validation pass or the error message if it fails
 */
const validateOrShowError = (value, validateFunction, errorMessage) => !validateFunction(value) ? errorMessage : true;
/**
 * Validate a value with a list of validators. It returns true if all validations pass or the error message of the first validation that failed.
 * @param {*} value - The value that needs validation
 * @param {Array.<{validateFunction: function, errorMessage: string}>} list - A list of validators with the appropriate error messages
 * @returns {(boolean|string)} true if all validations pass or the error message of the first validation that failed
 */


const validateOrShowMultipleErrors = (value, list) => {
  for (let i = 0; i < list.length; i++) {
    const {
      validateFunction,
      errorMessage
    } = list[i];

    if (!validateFunction(value)) {
      return errorMessage;
    }
  }

  return true;
};
/**
 * Validates if a string has at least one of the following symbols: !#$%^&*()+[]{}?:.
 * @param {string} value - The string that needs validation
 * @returns boolean
 */


const validateHasAtLeastOneSymbol = value => value && /.*[!#$%^&*()+[\]{}?:.].*/.test(value);
/**
 * Validates if a string has not include at least one of the following symbols: ^<>~;`
 * @param {string} value - The string that needs validation
 * @returns boolean
 */


const validateDoesNotContainSpecialSymbols = value => value && /^[^<>~;`]+$/.test(value);
/**
 * Validates if a string is a valid Email`
 * @param {string} value - The string that needs validation
 * @returns boolean
 */


const validateIsEmail = value => value && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
/**
 * Validates if a string is a valid Greek Tax ID`
 * @param {string} value - The string that needs validation
 * @returns boolean
 */


const validateTaxId = taxId => {
  if (!taxId || !taxId.match(/^\d{9}$/) || taxId === '000000000') {
    return false;
  }

  let m = 1;
  let sum = 0;

  for (let i = 7; i >= 0; i--) {
    m *= 2;
    sum += taxId.charAt(i) * m;
  }

  return sum % 11 % 10 === parseInt(taxId.charAt(8), 10);
};
/**
 * Validates if value is a valid Greek mobile phone. Works with or without prefix +30`
 * @param {string|number} value - The string that needs validation
 * @returns boolean
 */


const validateGreekMobilePhone = value => value && /^(\+30|0030)?69\d{8}$/.test(value);
/**
 * Validates if value is a valid Greek land line Phone. Works with or without prefix +30`
 * @param {string|number} value - The string that needs validation
 * @returns boolean
 */


const validateGreekLandlinePhone = value => value && /^(\+30|0030)?(2)\d{9}$/.test(value);
/**
 * Validates if value is a valid Greek land line or mobile phone. Works with or without prefix +30`
 * @param {string|number} value - The string that needs validation
 * @returns boolean
 */


const validateGreekPhone = value => validateGreekMobilePhone(value) || validateGreekLandlinePhone(value);
/**
 * Validates if value is a valid  mobile phone. Works without the prefix +30`
 * @param {string|number} value - The string that needs validation
 * @returns boolean
 */


const validateGreekMobilePhoneNoPrefix = value => value && /^69\d{8}$/.test(value);
/**
 * Validates if value is a valid  land line Phone. Works without the prefix +30`
 * @param {string|number} value - The string that needs validation
 * @returns boolean
 */


const validateGreekLandlinePhoneNoPrefix = value => value && /^2\d{9}$/.test(value);
/**
 * Validates if value is a valid land line or mobile phone. Works without the prefix +30`
 * @param {string|number} value - The string that needs validation
 * @returns boolean
 */


const validateGreekPhoneNoPrefix = value => validateGreekMobilePhoneNoPrefix(value) || validateGreekLandlinePhoneNoPrefix(value);

const STORAGE_TYPE = {
  Cookies: 'cookies',
  LocalStorage: 'local-storage',
  SessionStorage: 'session-storage'
};
Object.freeze(STORAGE_TYPE);

const getItem = ({
  key,
  storage = STORAGE_TYPE.Cookies
}) => {
  switch (storage) {
    case STORAGE_TYPE.Cookies:
      return Cookies.get(key);

    case STORAGE_TYPE.LocalStorage:
      return localStorage.getItem(key);

    case STORAGE_TYPE.SessionStorage:
      return sessionStorage.getItem(key);
  }
};

const setItem = ({
  key,
  value,
  storage = STORAGE_TYPE.Cookies,
  expiresInDays = null
}) => {
  switch (storage) {
    case STORAGE_TYPE.Cookies:
      if (expiresInDays) return Cookies.set(key, value, {
        expires: expiresInDays
      });
      return Cookies.set(key, value);

    case STORAGE_TYPE.LocalStorage:
      return localStorage.setItem(key, value);

    case STORAGE_TYPE.SessionStorage:
      return sessionStorage.setItem(key, value);
  }
};

const removeItem = ({
  key,
  storage = STORAGE_TYPE.Cookies
}) => {
  switch (storage) {
    case STORAGE_TYPE.Cookies:
      return Cookies.remove(key);

    case STORAGE_TYPE.LocalStorage:
      return localStorage.removeItem(key);

    case STORAGE_TYPE.SessionStorage:
      return sessionStorage.removeItem(key);
  }
};

const storage = {
  STORAGE_TYPE,
  getItem,
  setItem,
  removeItem
};

/**
 * Redirects to the passed url
 * @function redirectTo
 * @param {string} url - URL to redirect
 */
const redirectTo = url => {
  window.location.href = url;
};

/**
 *  Gets nested value from inside object
 * @param {object} object - The object that holds values
 * @param {string} path - The string that holds property name or path to property (eg user.address.street)
 * @returns any
 */
const getValueFromObject = (object, path) => {
  if (!object) return undefined;
  const pathValues = path.split('.');
  if (pathValues.length === 1) return object[path];
  let value = object;
  pathValues.forEach(property => {
    value = value ? value[property] : undefined;
  });
  return value;
};

export { getValueFromObject, redirectTo, storage, validateDoesNotContainSpecialSymbols, validateGreekLandlinePhone, validateGreekLandlinePhoneNoPrefix, validateGreekMobilePhone, validateGreekMobilePhoneNoPrefix, validateGreekPhone, validateGreekPhoneNoPrefix, validateHasAtLeastOneSymbol, validateIsEmail, validateOrShowError, validateOrShowMultipleErrors, validateTaxId };
