import React from 'react';
import { useTranslation } from 'react-i18next';

import { SingleColumnLayout } from '@wave/common';
import { Widget, PageTitle } from '@wave/components';

import CheckCouponForm from 'components/CheckCouponForm';

const CheckCoupon = () => {
  const { t, ready, i18n } = useTranslation(['merchant']);
  const translation = ready
    ? {
        title: t('merchant:title'),
        inputLabel: t('merchant:inputLabel'),
        buttonLabel: t('merchant:buttonLabel'),
      }
    : '';

  return (
    <SingleColumnLayout>
      <Widget backgroundColor="white">
        <PageTitle title={translation.title} />
        <CheckCouponForm translation={translation} lang={i18n.language} />
      </Widget>
    </SingleColumnLayout>
  );
};

export default CheckCoupon;
